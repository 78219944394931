<template>
  <div class="about">
    <svg
      width="100vw"
      height="80vh"
      viewBox="0 0 370 199"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <!-- Generator: Sketch 54.1 (76490) - https://sketchapp.com -->
      <title>404</title>
      <desc>Created with Sketch.</desc>
      <defs>
        <linearGradient
          x1="-47.0190124%"
          y1="-185.963888%"
          x2="68.8688465%"
          y2="100%"
          id="linearGradient-1"
        >
          <stop stop-color="#D2D2D2" offset="0%" />
          <stop stop-color="#D2D2D2" stop-opacity="0" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="118.999979%"
          y1="-110.864112%"
          x2="54.5018665%"
          y2="55.5871768%"
          id="linearGradient-2"
        >
          <stop stop-color="#D2D2D2" offset="0%" />
          <stop stop-color="#D2D2D2" stop-opacity="0" offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="-221.156912%" x2="50%" y2="100%" id="linearGradient-3">
          <stop stop-color="#D2D2D2" offset="0%" />
          <stop stop-color="#D2D2D2" stop-opacity="0" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="100.080959%"
          y1="-97.780461%"
          x2="38.826306%"
          y2="104.736078%"
          id="linearGradient-4"
        >
          <stop stop-color="#D2D2D2" offset="0%" />
          <stop stop-color="#D2D2D2" stop-opacity="0" offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="-230.116303%" x2="50%" y2="100%" id="linearGradient-5">
          <stop stop-color="#D2D2D2" offset="0%" />
          <stop stop-color="#D2D2D2" stop-opacity="0" offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="-169.203289%" x2="50%" y2="100%" id="linearGradient-6">
          <stop stop-color="#D2D2D2" offset="0%" />
          <stop stop-color="#D2D2D2" stop-opacity="0" offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-7">
          <stop stop-color="#D1D1D1" offset="0%" />
          <stop stop-color="#EAEAEA" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="50%"
          y1="15.2520789%"
          x2="83.9309294%"
          y2="55.3519373%"
          id="linearGradient-8"
        >
          <stop stop-color="#F2F1F1" offset="0%" />
          <stop stop-color="#F6F6F6" offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-9">
          <stop stop-color="#E2E1E1" offset="0%" />
          <stop stop-color="#FDFDFD" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="50%"
          y1="48.5196906%"
          x2="47.6845985%"
          y2="54.2041932%"
          id="linearGradient-10"
        >
          <stop stop-color="#FFFFFF" offset="0%" />
          <stop stop-color="#F7F7F7" offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-11">
          <stop stop-color="#EAEAEA" offset="0%" />
          <stop stop-color="#FCFCFC" stop-opacity="0" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="130.853945%"
          y1="-35.095306%"
          x2="130.853945%"
          y2="116.960465%"
          id="linearGradient-12"
        >
          <stop stop-color="#FFFFFF" offset="0%" />
          <stop stop-color="#478FFC" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="130.853945%"
          y1="-35.095306%"
          x2="130.853945%"
          y2="116.960465%"
          id="linearGradient-13"
        >
          <stop stop-color="#FFFFFF" offset="0%" />
          <stop stop-color="#478FFC" offset="100%" />
        </linearGradient>
      </defs>
      <g id="设计规范" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="08-缺省页规范" transform="translate(-280.000000, -333.000000)" fill-rule="nonzero">
          <g id="404" transform="translate(280.000000, 333.000000)">
            <g id="编组-2备份-9">
              <path
                d="M73.2592593,159.554082 C65.345679,155.973835 56.7654321,146.368897 59.1975309,137.331859 C60.4139859,137.020515 61.693852,137.516587 62.382716,138.566427 C62.382716,138.566427 76.1111111,160.010872 72.2592593,140.282477 C70.4444444,131.010872 67.3209877,122.047909 67.0740741,112.467662 C66.6666667,95.0602545 82.6419753,90.233094 87.2345679,109.467662 C87.8395062,112.010872 90.1604938,132.689884 95.4444444,130.319514 C96.1250336,129.961557 96.6773282,129.400632 97.0246914,128.714576 C99.7037037,124.319514 100.506173,117.862724 105.419753,116.368897 C109.728395,115.010872 113.975309,118.751613 116.296296,122.61581 C121,130.467662 127.296296,149.98618 117.666667,156.603464 C108.037037,163.220748 83.7407407,164.270131 73.345679,159.640501 L73.2592593,159.554082 Z"
                id="路径"
                fill="url(#linearGradient-1)"
              />
              <path
                d="M273.185185,180.430625 C269.703704,172.467662 269.790123,159.591119 277.691358,154.504699 C278.808047,155.104911 279.411159,156.355993 279.185185,157.603464 C279.185185,157.603464 274.975309,182.726921 285.358025,165.504699 C290.296296,157.393588 294,148.665193 300.17284,141.393588 C311.518519,128.134329 326.641975,135.331859 317.148148,152.677538 C315.91358,154.973835 303.740741,171.862724 309.246914,173.665193 C309.992355,173.848515 310.775891,173.801158 311.493827,173.52939 C316.432099,172.0726 321.37037,167.825687 326.037037,169.973835 C330.135802,171.862724 330.777778,177.467662 329.91358,181.899761 C328.135802,190.875069 319.421721,200.91212 307.841474,199.356564 C296.014314,197.751626 277.777778,190.924452 273.222222,180.517045 L273.185185,180.430625 Z"
                id="路径"
                fill="url(#linearGradient-2)"
              />
              <path
                d="M370.518519,199.356551 C323.962963,167.418279 258.17284,147.504699 185.259259,147.504699 C112.345679,147.504699 46.5555556,167.418279 0,199.356551 L370.518519,199.356551 Z"
                id="路径"
                fill="url(#linearGradient-3)"
              />
              <path
                d="M293.469136,103.060255 C290.518519,106.763958 288.62963,112.010872 290.395062,116.70223 C291.617284,119.936798 295.296296,119.961489 295.037037,124.455316 C294.783185,126.699169 294.014206,128.854 292.790123,130.751613 C290.704251,134.400024 288.14732,137.758071 285.185185,140.739267 C287.654305,142.665262 288.910389,145.760781 288.481481,148.862724 C287.997231,151.941616 286.539107,154.784309 284.320988,156.973835 C283.667879,157.772484 282.816415,158.385539 281.851852,158.751613 C280.917648,158.967389 279.94655,158.967389 279.012346,158.751613 C274.419062,157.972041 269.959209,156.546384 265.765432,154.517045 C263.148148,153.270131 260.481481,151.566427 259.592593,148.850378 C258.574074,145.733094 260.19348,144.742546 261.961179,143.765863 L262.41743,143.513691 C263.3302,143.004636 264.22575,142.451348 264.765432,141.566427 C266.061728,139.442971 266.098765,136.603464 266.555556,134.233094 C267.222222,130.739267 266.234568,124.368897 270.604938,122.998526 C272.296296,122.467662 274.135802,122.591119 275.82716,122.047909 C279.501372,120.878499 281.022443,117.422242 282.495104,113.927564 L282.762795,113.292245 C283.611763,111.282231 284.486968,109.304653 285.790123,107.78865 C287.641975,105.628156 292.234568,104.566427 293.469136,103.060255 Z M282.802469,123.78865 C282.802469,123.78865 269.506173,139.294822 269.938272,153.628156 L274.234568,153.628156 C274.234568,153.628156 275.790123,136.344205 282.802469,123.78865 Z"
                id="形状结合"
                fill="url(#linearGradient-4)"
              />
              <path
                d="M104.135802,22.0108718 L104.135802,22.0108718 C104.204481,17.2252075 100.786222,13.0986863 96.0713857,12.2755097 C91.3565496,11.4523331 86.7422233,14.1764226 85.1851852,18.7022298 C82.2145965,18.6439999 79.4492011,20.2121758 77.9742152,22.7913626 C76.4992294,25.3705494 76.5499916,28.54923 78.1065814,31.0800038 C79.6631712,33.6107776 82.4772299,35.0898519 85.4444444,34.9367977 L103.333333,34.9367977 C106.729866,34.891492 109.517593,32.2362321 109.72807,28.8459242 C109.938548,25.4556163 107.50065,22.4759385 104.135802,22.0108718 L104.135802,22.0108718 Z"
                id="路径"
                fill="url(#linearGradient-5)"
              />
              <path
                d="M178.074074,12.2084027 L178.074074,12.1466743 C178.066799,6.23154854 173.804253,1.17965438 167.974706,0.177082054 C162.14516,-0.825490268 156.439779,2.51210807 154.45679,8.08494589 C148.848713,8.17358419 144.374325,12.7916841 144.462963,18.3997607 C144.551601,24.0078373 149.169701,28.4822262 154.777778,28.3935879 L177,28.3935879 C181.281476,28.4012178 184.829217,25.075029 185.097306,20.8019475 C185.365395,16.528866 182.261202,12.7853409 178.012346,12.2577854 L178.074074,12.2084027 Z"
                id="路径"
                fill="url(#linearGradient-6)"
              />
            </g>
            <g id="编组-7" transform="translate(100.000000, 17.885211)">
              <g
                id="编组-3"
                transform="translate(97.750996, 96.024899) rotate(-15.000000) translate(-97.750996, -96.024899) translate(17.750996, 19.024899)"
              >
                <path
                  d="M80.0055866,0.924923097 C82.5587465,0.924923097 84.6284916,2.99543397 84.6284916,5.54953858 C84.6284916,7.78667443 83.0405877,9.65279823 80.9307736,10.0815219 L80.9301676,16.6486157 L79.0810056,16.6486157 L79.0809338,10.0816304 C76.9708535,9.65311327 75.3826816,7.78686327 75.3826816,5.54953858 C75.3826816,2.99543397 77.4524266,0.924923097 80.0055866,0.924923097 Z"
                  id="形状结合"
                  fill="url(#linearGradient-7)"
                />
                <ellipse
                  id="椭圆形"
                  fill="url(#linearGradient-8)"
                  cx="80.0055866"
                  cy="45.3212317"
                  rx="38.8324022"
                  ry="32.3723084"
                />
                <ellipse
                  id="椭圆形"
                  fill="url(#linearGradient-9)"
                  cx="80.0055866"
                  cy="66.594463"
                  rx="79.5139665"
                  ry="27.7476929"
                />
                <path
                  d="M138.949543,71.3844697 C139.029746,69.8541086 141.326968,68.7305875 144.080605,68.8748995 C146.834242,69.0192114 149.001536,70.3769014 148.921334,71.9072624 C148.841131,73.4376234 146.543909,74.5611446 143.790272,74.4168326 C141.036635,74.2725206 138.869341,72.9148307 138.949543,71.3844697 Z M21.9271055,66.8727393 C24.6639649,66.5365709 27.0339029,67.4971075 27.2206621,69.0181391 C27.4074213,70.5391708 25.3400334,72.0447441 22.603174,72.3809125 C19.8663146,72.7170809 17.4963766,71.7565443 17.3096174,70.2355127 C17.1228582,68.714481 19.1902461,67.2089077 21.9271055,66.8727393 Z M98.5086107,57.8344945 C98.642282,55.2838928 102.470986,53.4113575 107.06038,53.6518775 C111.649774,53.8923974 115.261932,56.155214 115.128261,58.7058157 C114.994589,61.2564174 111.165886,63.1289527 106.576492,62.8884327 C101.987097,62.6479127 98.3749393,60.3850962 98.5086107,57.8344945 Z M58.4982793,51.8020313 C63.0876736,51.5615113 66.9163772,53.4340466 67.0500486,55.9846483 C67.1837199,58.53525 63.5715619,60.7980665 58.9821676,61.0385865 C54.3927733,61.2791065 50.5640697,59.4065712 50.4303984,56.8559695 C50.296727,54.3053678 53.908885,52.0425513 58.4982793,51.8020313 Z"
                  id="形状结合"
                  fill="url(#linearGradient-10)"
                />
                <ellipse
                  id="椭圆形复制-6"
                  fill="#FFFFFF"
                  cx="80.0055866"
                  cy="74.9187708"
                  rx="42.4074488"
                  ry="10.1741541"
                />
                <path
                  d="M52.2615669,70.5738515 L4.53287326,141.8808 C51.6464813,154.709628 100.367441,156.936221 150.695752,148.56058 C150.934616,148.608185 136.5336,122.595683 107.492705,70.523074 L79.1509531,70.5491304 L52.2615669,70.5738515 Z"
                  id="路径-2"
                  fill="url(#linearGradient-11)"
                />
                <g id="编组-8" transform="translate(38.980015, 77.539787)">
                  <polygon
                    id="路径"
                    fill="url(#linearGradient-12)"
                    points="24.7363568 16.0811121 13.2427788 33.3246971 16.4088338 33.9539273 17.6592926 27.6214617 27.4900265 29.5894796 26.2395678 35.9219452 29.9643384 36.6716663 28.4478247 44.4232468 24.723054 43.6735257 23.4592926 50.0461549 13.6285586 48.0781371 14.8923201 41.7055078 0.964338424 38.9208295 2.34782466 31.8788065 14.5863568 14.0461549"
                  />
                  <path
                    d="M55.406699,20.808529 C52.5890648,31.5557274 46.3500176,36.2525509 37.9239495,34.0056555 C29.4039604,31.7316891 26.31798,24.5578665 29.1356142,13.8106681 C31.9532485,3.06346974 38.1520437,-1.63335374 46.6720329,0.627077156 C55.098101,2.88750805 58.2243332,10.0613306 55.406699,20.808529 Z M39.2925147,16.517771 C37.4945958,23.3938123 37.5214304,26.1009151 39.8426243,26.7100133 C42.110149,27.3191114 43.4787141,24.9774674 45.2766331,18.1014262 C47.0879694,11.1712428 47.0477175,8.51828201 44.7801928,7.92271938 C42.4455816,7.30008572 41.103851,9.58758764 39.2925147,16.517771 L39.2925147,16.517771 Z M72.9162831,16.1252411 L64.9195688,35.5080976 L68.1799741,35.5080976 L68.1799741,28.9839797 L78.2832054,28.9839797 L78.2832054,35.5080976 L82.1205549,35.5080976 L82.1205549,43.494051 L78.2832054,43.494051 L78.2832054,50.072311 L68.1799741,50.072311 L68.1799741,43.494051 L53.8502915,43.494051 L53.8502915,36.2390154 L62.4776192,16.1252411 L72.9162831,16.1252411 L72.9162831,16.1252411 Z"
                    id="形状"
                    fill="url(#linearGradient-13)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
  created() {
    // this.getroutelist();
  },
};
</script>
